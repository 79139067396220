<template>
  <div id="search-panel">
    <div class="row">
      <div class="col-lg-6 col-md-6 search-box">
        <PlaylistSearch />
      </div>
      <div class="col-lg-6 col-md-6 search-box">
        <SongSearch />
      </div>
    </div>
    <div class="row">
      <div class="col-lg-6 col-md-6 search-box">
        <ArtistSearch />
      </div>
      <div class="col-lg-6 col-md-6 search-box">
        <AlbumSearch />
      </div>
    </div>
    <div class="row">
      <div class="col-lg-6 col-md-6 search-box">
        <SkinSearch />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  components: {
    SongSearch: () => import('@/components/search/SongSearch'),
    ArtistSearch: () => import('@/components/search/ArtistSearch'),
    AlbumSearch: () => import('@/components/search/AlbumSearch'),
    PlaylistSearch: () => import('@/components/search/PlaylistSearch'),
    SkinSearch: () => import('@/components/search/SkinSearch'),
  },
};
</script>

<style>
#search-panel {
  height: 5vh;
  width: 100vw;
  z-index: 1000;
}

.search-box {
  height: 40px;
}
</style>
